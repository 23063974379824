import { Button, Clickable, Icon, Label, Skeleton } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { FC } from 'react';

import { LoadableMapSearchModal } from '@Components/MapSearch/LoadableMapSearchModal';
import { useModal } from '@Components/Modal/useModal';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { useBreakpoint } from '@Core/useBreakpoint';
import { useSearchResultsStore, useSearchSelectionStore } from '@Stores/StoreContext';

const isServer = typeof window === 'undefined';

type MapSearchButtonProps = {
  className?: string;
};

export const MapSearchButton: FC<MapSearchButtonProps> = ({ className }) => {
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const { t } = useTranslation();

  const { isDesktop } = useBreakpoint();

  const setViewMode = useSearchSelectionStore((state) => state.setViewMode);
  const [fetching, results] = useSearchResultsStore((state) => [state.fetching, state.results]);
  const isLoading = fetching && results.length === 0;

  const onMapOpen = () => {
    setModalOpen();
    setViewMode('map');
  };

  const onMapClose = () => {
    setViewMode('list');
    setModalClose();
  };

  const onMapBarClick = () => {
    trackEventClick('map');
    onMapOpen();
  };

  // Is server is used to avoid calling is desktop on server
  const showStandaloneMapButton = isServer || isDesktop();

  if (isLoading && showStandaloneMapButton) {
    return (
      <Skeleton
        className={className}
        sx={{
          height: 36,
          width: 107,
          display: 'flex',
        }}
      />
    );
  }

  return (
    <Clickable
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: ['100%', null, 'auto'],
      }}
      className={className}
      onClick={onMapBarClick}
    >
      <Button
        size="36"
        variant="PrimaryDefault"
        onClick={onMapOpen}
        icon="Content/PlaceMap"
        sx={{ display: ['none', 'none', 'flex'] }}
      >
        <Label variant="mediumbold">{t('map')}</Label>
      </Button>

      <div
        sx={{
          display: ['flex', null, 'none'],
        }}
      >
        <Icon
          name="Content/PlaceMap"
          size="16"
          sx={{
            marginRight: '3xs',
          }}
        />
        <Label
          variant="mediumbold"
          sx={{ color: 'textPrimarydark' }}
        >
          {t('map')}
        </Label>
      </div>

      <LoadableMapSearchModal
        isShown={isModalOpen}
        onClose={onMapClose}
      />
    </Clickable>
  );
};
