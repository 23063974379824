import { Icon, IconProps, Label } from '@loveholidays/design-system';
import React, { Fragment, ReactElement, useMemo, ComponentProps } from 'react';

import { ControlType, DynamicPackageFilterOption, DynamicPackageFiltersInput } from '@AuroraTypes';
import { HastNode } from '@Core/types';
import { Filter as FilterComponent, FilterItemModel } from '@DesignSystemComponents/Filter';
import { useSearchSelectionStore } from '@Stores/StoreContext';

export interface SearchFilterProps {
  id: keyof DynamicPackageFiltersInput;
  icon: IconProps['name'];
  title: string | ReactElement;
  label?: string;
  controlType: ControlType;
  options: DynamicPackageFilterOption[];
  tooltip?: HastNode;
  footerContent?: string;
  isInModal?: boolean;
}

export interface SelectItemIdsFormatterProps {
  id: keyof DynamicPackageFiltersInput;
  controlType: ControlType;
  selectedItemIds: Parameters<ComponentProps<typeof FilterComponent>['onChange']>[0];
}

export function seletedItemIdsFormatter({
  selectedItemIds,
  id,
  controlType,
}: SelectItemIdsFormatterProps) {
  if (controlType === 'RANGE' && Array.isArray(selectedItemIds)) {
    // The range filter has an issue in it where nested arrays can be provided here. We need
    // to make the value safe here to ensure we don't provide invalid data to Aurora. We
    // should fix the root cause but first lets see if this fixes the Sentry noise.
    const flattened = selectedItemIds.flat();

    return { [id]: flattened.length ? flattened[0] : undefined };
  }
  if (
    id === 'includedCheckedBags' &&
    controlType === 'STEPPER' &&
    Number.isInteger(selectedItemIds) &&
    selectedItemIds !== undefined
  ) {
    // Aurora types is expecting an array of numbers for includedCheckedBags
    // but the default stepper filter provides single number input
    // added this to convert number to number[]
    return { [id]: [Number(selectedItemIds)] };
  }

  return { [id]: selectedItemIds };
}

/**
 * Renders one search filter (e.g. star rating).
 */
export const SearchFilter: React.FC<SearchFilterProps> = ({
  id,
  icon,
  title,
  label,
  controlType,
  options,
  tooltip,
  footerContent,
  isInModal = false,
}) => {
  const [selectedOptions, setFilterSelection] = useSearchSelectionStore((state) => {
    let selectedOptions = state.filters[id];

    if (controlType === 'CHECKBOX' && !selectedOptions) {
      selectedOptions = [];
    }

    return [selectedOptions, state.setFilterSelection];
  });

  const items: FilterItemModel[] = useMemo(
    () =>
      options.map<FilterItemModel>(({ value, label, subLabel, count }) => {
        const isSelected = Array.isArray(selectedOptions)
          ? (selectedOptions as (typeof value)[]).includes(value)
          : selectedOptions === value;

        return {
          id: value,
          label,
          subLabel,
          // We should not disable selected options to allow de-selecting them
          disabled: count === 0 && !isSelected,
          info: count ? <Label variant="small">{count > 500 ? '500+' : count}</Label> : undefined,
        };
      }),
    [options, selectedOptions],
  );

  if (!options.length) {
    return null;
  }

  return (
    <FilterComponent
      id={id}
      type={controlType}
      icon={icon}
      isInModal={isInModal}
      footerContent={footerContent}
      title={
        <Fragment>
          {title}
          {!!icon && (
            <Icon
              name={icon}
              size="20"
              sx={{
                marginLeft: '3xs',
                verticalAlign: 'text-top',
              }}
            />
          )}
        </Fragment>
      }
      label={label}
      titleAs="h3"
      tooltip={tooltip}
      items={items}
      selectedItemIds={
        (controlType === 'CHECKBOX'
          ? selectedOptions
          : [selectedOptions].filter(Boolean)) as string[]
      }
      onChange={(selectedItemIds) => {
        const itemIdsToUse = seletedItemIdsFormatter({ selectedItemIds, id, controlType });
        setFilterSelection(itemIdsToUse);
      }}
      sx={{
        marginBottom: '2xs',
        backgroundColor: 'backgroundWhite',
        borderWidth: 'outlinedStrokeWeight',
        borderStyle: 'solid',
        borderColor: 'strokeLightneutral',
        borderRadius: 16,
      }}
    />
  );
};
