import { Title, TitleProps, TitleVariants } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { ClassNameProps } from '@ComponentProps';
import { useAppContext } from '@Contexts/contexts';
import { useSearchResultsStore } from '@Stores/StoreContext';

interface TotalResultsCounterProps {
  variant?: TitleVariants;
  as?: TitleProps['as'];
}

export const TotalResultsCounter: React.FC<TotalResultsCounterProps & ClassNameProps> = ({
  className,
  variant = 'small',
  as = 'h1',
}) => {
  const { t } = useTranslation();
  const {
    site: { secondaryLocale: overrideLocale },
  } = useAppContext();
  const [fetching, paginating, count] = useSearchResultsStore((state) => [
    state.fetching,
    state.paginating,
    state.totalResults,
  ]);

  const isEmpty = !fetching && !count;
  const showCounter = (!fetching || paginating) && !!count;

  return (
    <Title
      variant={variant}
      as={as}
      sx={{
        ...(isEmpty && { visibility: 'hidden' }),
        scrollMarginTop: ({ space }) => space['3xs'],
      }}
      className={className}
    >
      {showCounter ? t('xHolidaysFound', { overrideLocale, count }) : t('findingYourHoliday')}
    </Title>
  );
};
