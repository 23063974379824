import { Title, Paragraph } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

type NoAvailabilityMessageProps = Pick<ClassNameProps, 'className'>;

export const NoAvailabilityMessage: React.FC<NoAvailabilityMessageProps> = ({ className }) => {
  const { t } = useTranslation();

  const [resetFilters, hasActiveFilters, resetSearchSelection] = useSearchSelectionStore(
    (state) => [state.resetFilters, state.hasActiveFilters(), state.resetSearchSelection],
  );

  const [hasNightsSelection] = useSearchAvailabilityStore((state) => [state.hasNightsSelection()]);

  return (
    <div className={className}>
      <Title
        variant="small"
        as="h2"
        sx={{
          marginBottom: '2xs',
        }}
      >
        {t('noAvailability.titleSRP')}
      </Title>

      {hasActiveFilters || !hasNightsSelection ? (
        <Fragment>
          <Paragraph
            variant="medium"
            sx={{
              marginBottom: 'xl',
            }}
          >
            {t('noAvailability.messageFilter')}
          </Paragraph>
          <Button
            data-id={hasActiveFilters ? 'reset-filters-button' : 'reset-search-button'}
            variant="Primary"
            onClick={hasActiveFilters ? resetFilters : resetSearchSelection}
            size="48"
            sx={{
              marginTop: '2xs',
              marginBottom: ['l', '3xl'],
            }}
          >
            {t('filters.resetFilters')}
          </Button>
        </Fragment>
      ) : (
        <Paragraph
          variant="medium"
          sx={{
            marginBottom: ['2xl', '3xl'],
          }}
        >
          {t('noAvailability.messageInitialSearch')}
        </Paragraph>
      )}
    </div>
  );
};

export default NoAvailabilityMessage;
